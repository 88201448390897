<script setup
        lang="ts">
import { ref }           from 'vue';
import { MouseParallax } from '@tresjs/cientos';
import { TresCanvas }    from '@tresjs/core';
import Inner             from './inner.vue';

const disabled = ref(true);
</script>

<template>
    <div class="header-animation--wrapper">
        <TresCanvas class="canvas"
                    preset="realistic"
                    :class="[{
                        disabled,
                    }]">
            <TresPerspectiveCamera :position="[60, 60, 60]"
                                   :zoom="1.6"
                                   cast-shadow
                                   :look-at="[5, 5, -5]" />
            <Inner @drag-start="disabled = false"
                   @drag-end="disabled = true" />
            <MouseParallax :factor="8"
                           :ease="4" />
        </TresCanvas>
    </div>
</template>

<style lang="scss"
       scoped>
.header-animation--wrapper {
    width:      100%;
    min-height: calc((var(--vh, 1vh) * 75) - 4rem);

    @include tablet {
        width:      100%;
        min-height: calc((var(--vh, 1vh) * 100) - 8rem);
    }

    .canvas {
        &.disabled {
            touch-action: auto !important;
        }
    }
}
</style>
